define("managing-tool-frontend/we-manage/service-management/overview-user/route", ["exports", "managing-tool-frontend/app", "jquery"], function (_exports, _app, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      usersOffset: {
        refreshModel: true
      }
    },

    activate() {
      this.controllerFor("weManage.serviceManagement").send("switchBreadcrumb", 4);
    },

    renderTemplate: function () {
      this.render("weManage/serviceManagement/overviewUser", {
        into: "weManage",
        outlet: "maincontent"
      });
    },
    model: function (params) {
      return this.store.query("user", {
        limit: 18,
        offset: params.usersOffset
      }).then(users => {
        return {
          users: users,
          usersM: users.get("meta")
        };
      });
    },
    afterModel: function ({
      users
    }) {
      users.forEach(function (user) {
        user.set("selected", false);
      });
    },

    setupController(controller, {
      users,
      usersM
    }) {
      this._super(controller, {
        users,
        usersM
      });

      users.forEach(function (user) {
        let taskLink = user.get('links.tasks');
        let lsat = JSON.parse(localStorage.getItem('ember_simple_auth-session'))['authenticated']['access_token'];

        _jquery.default.ajax({
          type: 'GET',
          url: _app.default.API_HOST + taskLink + "&states=1!2!5!6&limit=20&offset=0",
          data: '',
          processData: false,
          contentType: false,
          headers: {
            'Authorization': 'Bearer ' + lsat
          },
          success: function (data) {
            if (typeof data.error === 'undefined') {
              let unseenTasks = 0;
              user.set('allTasks', data.meta.count);

              for (let i = 0; i < data.tasks.length; i++) {
                if (data.tasks[i].state == 5) {
                  user.set('inProgressId', data.tasks[i].id);
                  user.set('inProgressSubject', data.tasks[i].subject);
                } else if (data.tasks[i].state == 1) {
                  unseenTasks++;
                }
              }

              user.set('unseenTasks', unseenTasks);
            } else {
              console.error('ajax success with error: ', data);
            }
          },
          error: function (jqXHR, textStatus) {
            console.error('ajax error: ', textStatus);
          }
        });
      });
      controller.set("usersTotalPages", Math.ceil(usersM.count / 18));
    },

    actions: {
      loadUser: function (user) {
        return this.transitionTo("weManage.serviceManagement.overviewUser.user", user);
      }
    }
  });

  _exports.default = _default;
});